/* Fonts Form Google Font ::- https://fonts.google.com/  -:: */
@import url('https://fonts.googleapis.com/css?family=Abel|Abril+Fatface|Alegreya|Arima+Madurai|Dancing+Script|Dosis|Merriweather|Oleo+Script|Overlock|PT+Serif|Pacifico|Playball|Playfair+Display|Share|Unica+One|Vibur');
/* End Fonts */
/* Start Global rules */
/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
} */
/* End Global rules */

/* Start body rules */
.body-login {
    /* background-image: linear-gradient(-225deg, #084c3f 100%, #F69B30 75%); */
    /* background-image: linear-gradient(to top, #084c3f 100%, #F69B30 75%);  */
    background-attachment: fixed;
    background-repeat: no-repeat;
    /* font-family: 'Vibur', cursive; */
/*   the main font */
    /* font-family: 'Abel', sans-serif; */
    opacity: .95;
/* background-image: linear-gradient(to top, #d9afd9 0%, #97d9e1 100%); */
}
/* |||||||||||||||||||||||||||||||||||||||||||||*/
/* //////////////////////////////////////////// */
/* End body rules */
/* Start form  attributes */
.form-login {
    /* float: right; */
    /* width: 450px; */
    /* min-height: 500px; */
    /* height: auto; */
    /* border-radius: 5px; */
    /* margin: 8%; */
    /* box-shadow: 0 9px 50px hsla(20, 67%, 75%, 0.31); */
    /* padding: 2%; */
    /* background-color: #FFFFFF; */

    width: 450px;
    min-height: 400px;
    border-radius: 5px;
    box-shadow: 0 9px 50px hsl(20deg 67% 75% / 31%);
    padding: 2%;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: 0.3s;
    right: 100px;
    /* float: right; */
    /* max-height: 410px; */
    /* margin: 8%; */
}
.sidebar-brand-logo {
    max-width: 200px;
    border-radius: 4px;
    overflow: hidden;
}
.img-login {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
.other p{
    text-align: center;
}
.login-text h3{
    font-size: 28px;
    line-height: 1.2em;
    margin-bottom: 0.2rem;
    font-weight: 500;
    margin-top: 0;
}
.login-text p{
    color: rgb(109, 113, 117);
    margin: 0;
    font-size: 16px;
    margin-bottom: 20px;
}
.artwork {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 0;
    background-color: #084c3f;
    height: 100%;
    transform: rotate(180deg);
}
.ui-submit-btn {
    box-shadow: 0 1px 0 rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 20%);
    background: rgb(0, 128, 96, 1);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 16px 24px;
    margin: 12px 0;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}
@media only screen and (max-width: 600px) {
    .artwork {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 0;
        background-color: #084c3f;
        height: 100%;
        width: 0%;
    }
    .form-login {
        width: auto;
    }
}

.fill-page {
    width: auto;
    height: 100vh;
    /* min-height: 200vh; */
    background-color: #084C3F;
}

    /* margin: auto 10px; */

@media only screen and (max-height: 625px) {
    .form-login {
        /* float: right;
        width: 450px;
        min-height: 400px;
        max-height: 410px;
        border-radius: 5px;
        margin: 8%;
        box-shadow: 0 9px 50px hsla(20, 67%, 75%, 0.31);
        padding: 2%;
        background-color: #FFFFFF;
         */
         
        width: 450px;
        min-height: 400px;
        border-radius: 5px;
        box-shadow: 0 9px 50px hsl(20deg 67% 75% / 31%);
        padding: 2%;
        background-color: #FFFFFF;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #999;
        font-size: 18px;
        transition: 0.3s;
        right: 100px;
        /* float: right; */
        /* max-height: 410px; */
        /* margin: 8%; */
    }
    .login-text h3{
        font-size: 28px;
        line-height: 1.2em;
        /* margin-bottom: 0.2rem; */
        font-weight: 500;
        margin-top: 0;
    }
    .login-text p{
        color: rgb(109, 113, 117);
        margin: 0;
        font-size: 16px;
        /* margin-bottom: 20px; */
    }
}

/* @media only screen and (max-height: 578px) {
    .form-login {
        float: right;
        width: 450px;
        min-height: 100px;
        height: auto;
        border-radius: 5px;
        margin: 8%;
        box-shadow: 0 9px 50px hsla(20, 67%, 75%, 0.31);
        padding: 2%;
        background-color: #FFFFFF;
    }
} */