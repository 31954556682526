@import url("https://fonts.googleapis.com/css?family=inter:300,400,500,600,700,800|Poppins:500,500,600,700,800,900");
div#navbarSupportedContent {
    justify-content: center;
} 
.bg-dark{
    background-color: transparent !important;
}
#logsedf{
    width: 75%;
}
.navbar-nav .nav-link {
    color: #000000;
    font-family: 'inter' , sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7em;
    padding: 8px 25px !important;
    text-transform: uppercase;
}
.navbar-brand {
    font-family: 'inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    text-transform: uppercase;
}
.active-2, .nav-link-2:hover {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #F69B31 !important;
    text-transform: uppercase;
}
.navbar-brand:hover{
    color: aqua;
}
.nav-right-content {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}
.nav-right-content .info-bar-item-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}
.navtit {
    font-family: 'inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #283E4B;
    text-transform: uppercase;
    text-decoration: none;
}
.author-nav-content{
    background-color: #283E4B;
    color: #F69B31;
    padding: 5px 15px;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'inter', sans-serif;
    font-style: normal;
    font-weight: 500;
}
.slider-section{
    background: url(../images/head-img.png);
    width: 100%;
    height: 770px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.banner-text{
    width: 60%;
    height: auto;
    margin: auto;
    text-align: center;
    margin-top: 410px;
}
#heaimg{
    width: 35%;
    height: auto;
}
.head-text p{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}
.col-img-bg{
    background: #D9D9D9;
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
    width: 40%;
    padding: 35px 20px;
    margin: auto;
    border-radius: 100px;
    margin-top: -40px;
}
#colimg{
    width: 80%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.row-section{
    width: 100%;
    height: auto;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
}
.col-text{
    text-align: center;
}
.col-text h4{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    text-transform: uppercase;
    color: #293E4A;
    margin-top: 15px;
}
.col-text p{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    text-align: center;
    color: #474747;
}
.features-section{
    background: url(../images/bg-con.png);
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
}
.feat-bg{
    background: #FFFFFF;
    box-shadow: 0px 0px 9px 7px rgba(0, 0, 0, 0.25);
    padding: 20px;
}
.feat-text {
    text-align: center;
}
.feat-text h3{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 77px;
    text-transform: uppercase;
    color: #293E4A;
}
.feat-text p{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 190.52%;
    text-align: center;
    color: #474747;
}
.featuresrow-section{
    width: 100%;
    height: auto;
    padding: 40px;
}
.colbgfe{
    background: #D9D9D9;
    border-radius: 6px;
    padding: 10px;
    height: 400px;
    margin-bottom: 25px;
}
.col-fe-bg{
    background: #293E4A;
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
    width: 40%;
    padding: 40px 20px;
    margin: auto;
    border-radius: 100px;
}
.colfe-text {
    text-align: center;
}
.colfe-text h4{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    text-transform: uppercase;
    color: #293E4A;
    margin-top: 15px;
}
.colfe-text  p{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #474747;
}
.opt-section{
    width: 100%;
    height: auto;
    padding: 40px;
}
.optbg-col{
    background: #D9D9D9;
    border-radius: 6px;
    padding: 15px;
}
.opt-divs{
    display: flex;
}
.col-opt-bg{
    background: #293E4A;
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
    width: 40%;
    padding: 46px 30px;
    margin: auto;
    border-radius: 100px;
    margin-right: 15px;
}
.colopt-text h4{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 39px;
    /* identical to box height */
    color: #293E4A;
}
.colopt-text p{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 170.02%;
    color: #474747;
}
.redi-section{
    background: #FB951E;
    mix-blend-mode: multiply;
    padding: 40px;
    width: 100%;
    height: auto;
    margin-bottom: 8px;
}
.redi-text p{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 190.52%;
    color: #474747;
}
#imgredi{
    width: 85%;
    height: auto;
    margin: auto;
}
.redi-img{
    text-align: center;
}
.redi-text {
    margin-top: 15px;
}
.btn-secondary-2{
    background: #293E4A;
    border-radius: 6px;
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-transform: uppercase;
    color: #F69B31;
}
.btn-secondary-2:hover{
    background: #293E4A;
    border-radius: 6px;
    color: #F69B31;
}
.footer-section{
    background-color: #293E4A;
    width: 100%;
    height: auto;
    padding: 40px;
}
.footer-social ul {
    padding: 0px 0px;
}
.footer-social ul li {
    display: inline-block;
    margin: 0 7px;
    background: #F69B31;
    border-radius: 20px;
    color: #293E4A;
}
.footer-social ul li a {
    background: #F69B31;
    border-radius: 20px;
    color: #293E4A;
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
}
#footerlog{
    width: 50%;
    height: auto;
}
.bannerslider-section{
    background: #F69B31; 
    padding: 100px;
    margin-bottom: 8px;
}
.serhead-text h1{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 158.52%;
    text-align: center;
    text-transform: uppercase;
    color: #293E4A;
}
.footer-text h5{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size:   20px;
    line-height: 39px;
    color: #FB951E;
}
.footer-menu ul {
    padding: 0px 15px;
}
.footer-menu ul li {
    list-style: none;
    padding: 5px 5px;
}
#foot-icon {
    color: #FB951E;
    font-size: 14px;
    font-weight: 400;
}
.footer-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    padding-left: 15px;
}
.footerb-text p {
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding-top: 10px;
}
.abo-section{
    width: 100%;
    height: auto;
    padding: 50px;
}
.abt-text h3{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 158.52%;
    text-transform: uppercase;
    color: #293E4A;
}
.abt-text p{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 198.52%;
    color: #2D2D2D;
}
#imgabt{
    width: 80%;
    height: auto;
    margin: auto;
}
.abt-img{
    text-align: center;
}
.mgt-4{
    margin-top: 1.5rem;
}
.faq-text h3{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 158.52%;
    text-transform: uppercase;
    color: #293E4A;
}
.accordion-button {
    font-size: 16px;
    font-weight: 600;
    font-family: "inter", sans-serif;
    color: #474747;
}
.accordion-button:not(.collapsed) {
    color: #474747 !important;
    background-color: #D9D9D9 !important;
}
.did-find{
    background: #D9D9D9;
    padding: 30px;
}
.did-text{
    text-align: center;
}
.did-text h5{
    font-family: 'Inter' ,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 158.52%;
    color: #293E4A;
}
.did-text p{
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 158.52%;
    text-align: center;
    color: #000000;
}
.faq-section{
    background: url(../images/faq-bg.png);
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
}
.contact-section{
    width: 100%;
    height: auto;
    padding: 50px;
}
.cont-text h3{
    text-align: center;
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 165.52%;
    color: #293E4A;
}
.cont-bg{
    background-color: #F69B31;
    padding: 30px;
}
.fomfelid{
    background: #EFEFEF;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
}
.sign-in{
    display: none;
}
.card-2 {
    background: #F69B31;
    box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}
.input-group-text {
    border-radius: 0rem !important;
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
}
.icon {
    padding: 8px;
    min-width: 10px;
    text-align: center;
}
.label-text{
    font-family: 'inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #283E4B;
}
#sigimg{
    width: 108%;
    height: auto;
}



@media screen and (min-width:768px) and (max-width:991px) {
    a.nav-link {
        margin-left: 0;
        font-size: 14px;
        font-family: 'inter', sans-serif;
        color: #ffffff !important;
        height: 47px;
        padding: 23px 0;
        background-color: #333333 !important;
        text-align: center;
    }
    #logsedf{
        width: 55%;
    }
    .nav-right-content{
        display: none;
    }
    .sign-in {
        display: unset;
    }
    .slider-section {
        width: 100%;
        height: 445px;
    }
    .head-text p {
        font-weight: 600;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
    }
    .banner-text {
        width: 80%;
        height: auto;
        margin: auto;
        text-align: center;
        margin-top: 210px;
    }
    .col-img-bg {
        width: 60%;
        padding: 25px 20px;
        margin: auto;
        border-radius: 100px;
        margin-top: -23px;
    }
    #colimg {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .col-text p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }
    .row-section {
        width: 100%;
        height: auto;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
    }
    .col-text h4 {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        margin-top: 15px;
    }
    .features-section {
        width: 100%;
        height: auto;
        padding: 30px;
    }
    .feat-text h3 {
        font-weight: 700;
        font-size: 33px;
        line-height: 50px;
    }
    .col-fe-bg {
        width: 50%;
        padding: 25px 20px;
    }
    .colfe-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }
    .col-opt-bg {
        width: 75%;
        padding: 27px 22px;
        margin-right: 15px;
    }
    .opt-section {
        width: 100%;
        height: auto;
        padding: 20px;
    }
    .colopt-text h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
    }
    .colopt-text p {
        font-weight: 500;
        font-size: 12px;
        line-height: 170.02%;
    }
    #imgredi {
        width: 100%;
        height: auto;
        margin: auto;
    }
    .redi-text p {
        font-weight: 600;
        font-size: 14px;
        line-height: 150.52%;
    }
    .redi-section {
        padding: 25px;
        width: 100%;
        height: auto;
    }
    .footer-social ul li {
        margin: 0px 2px;
    }
    .footer-section {
        width: 100%;
        height: auto;
        padding: 30px;
    }
    .bannerslider-section {
        background: #F69B31;
        padding: 55px;
        margin-bottom: 8px;
    }
    .serhead-text h1 {
        font-weight: 700;
        font-size: 40px;
        line-height: 158.52%;
    }
    #imgabt {
        width: 100%;
        height: auto;
        margin: auto;
    }
    .abt-img {
        text-align: center;
        margin-top: 40px;
    }
    .abo-section {
        width: 100%;
        height: auto;
        padding: 30px;
    }
    .abt-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 170.52%;
    }
    .feat-text p {
        font-weight: 600;
        font-size: 14px;
        line-height: 190.52%;
    }
    .faq-section {
        width: 100%;
        height: auto;
        padding: 25px;
    }
    .faq-text h3 {
        font-weight: 700;
        font-size: 22px;
        line-height: 158.52%;
    }
    .did-find {
        background: #D9D9D9;
        padding: 15px;
    }
    .did-text h5 {
        font-weight: 600;
        font-size: 16px;
    }
    .did-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 158.52%;
    }
    .contact-section {
        width: 100%;
        height: auto;
        padding: 30px;
    }
    .sig-img{
        display: none;
    }
}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .navbar .navbar-brand {
        width: 70%;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
        justify-content: center;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: #fff !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        font-family: 'inter', sans-serif;
    }
    a.nav-link {
        margin-left: 0;
        font-size: 14px;
        font-family: 'inter', sans-serif;
        color: #ffffff !important;
        height: 37px;
        padding: 23px 0;
        background-color: #333333 !important;
        text-align: center;
    }
    .navbar-brand {
        font-weight: 300;
        font-size: 10px;
    }
    #logsedf{
        width: 60%;
    } 
    .nav-right-content{
        display: none;
    }
    .sign-in {
        display: unset;
    }
    .serhead-text h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 158.52%;
        color: #293E4A;
    }
    .bannerslider-section {
        padding: 50px;
        margin-bottom: 8px;
    }
    .contact-section {
        width: 100%;
        height: auto;
        padding: 25px;
    }
    .cont-text h3 {
        font-weight: 700;
        font-size: 26px;
        line-height: 165.52%;
    }
    .cont-bg {
        padding: 20px;
    }
    .footer-section {
        width: 100%;
        height: auto;
        padding: 25px;
    }
    .faq-section {
        width: 100%;
        height: auto;
        padding: 20px;
    }
    .feat-bg {
        padding: 12px;
    }
    .faq-text h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 158.52%;
    }
    .accordion-button {
        font-size: 14px;
        font-weight: 600;
    }
    .did-find {
        padding: 15px;
    }
    .abo-section {
        width: 100%;
        height: auto;
        padding: 25px;
    }
    #imgabt {
        width: 100%;
        height: auto;
        margin: auto;
    }
    .features-section {
        width: 100%;
        height: auto;
        padding: 20px;
    }
    .abt-text h3 {
        font-weight: 700;
        font-size: 28px;
        line-height: 158.52%;
    }
    .abt-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 198.52%;
    }
    .feat-text h3 {
        font-weight: 700;
        font-size: 28px;
        line-height: 35px;
    }
    .feat-text p {
        font-weight: 600;
        font-size: 14px;
        line-height: 190.52%;
    }
    .featuresrow-section {
        width: 100%;
        height: auto;
        padding: 20px;
    }
    #colimg {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .col-fe-bg {
        width: 50%;
        padding: 40px 35px;
        margin: auto;
        border-radius: 100px;
    }
    .colfe-text h4 {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #293E4A;
        margin-top: 15px;
    }
    .colfe-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
    }
    .opt-section {
        width: 100%;
        height: auto;
        padding: 15px;
    }
    .optbg-col {
        border-radius: 6px;
        padding: 8px;
        margin-top: 15px;
    }
    .colopt-text h4 {
        font-size: 20px;
        line-height: 39px;
    }
    .colopt-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 170.02%;
    }
    .col-opt-bg {
        width: 50%;
        padding: 40px 35px;
        margin: auto;
    }
    .opt-divs {
        display: block;
    }
    .optbg-col {
        border-radius: 6px;
        padding: 15px;
        margin-top: 15px;
    }
    .colopt-text{
        text-align: center;
    }
    .redi-section {
        background: #FB951E;
        mix-blend-mode: multiply;
        padding: 18px;
        width: 100%;
        height: auto;
        margin-bottom: 8px;
    }
    .redi-img {
       margin-top: 25px;
    } 
    #imgredi {
        width: 100%;
        height: auto;
        margin: auto;
    }
    .redi-text p {
        font-weight: 600;
        font-size: 14px;
        line-height: 190.52%;
    }
    .slider-section {
        width: 100%;
        height: 210px;
    }
    .col-img-bg {
        width: 40%;
        padding: 35px 30px;
        margin: auto;
        margin-top: 0px; 
    }
    .row-section {
        width: 100%;
        height: auto;
        padding: 20px;
    }
    .banner-text {
        width: 85%;
        height: auto;
        margin: auto;
        text-align: center;
        margin-top: 105px;
    }
    .banner-text {
        width: 85%;
        height: auto;
        margin: auto;
        text-align: center;
        margin-top: 105px;
    }
    .head-text{
        display: none;
    }
    .head-btn{
        margin-top: 20px;
    }
    #heaimg {
        width: 40%;
        height: auto;
    }
    .sig-img{
        display: none;
    }
    .py-5 {
        padding-top: 0rem!important; 
        padding-bottom: 0rem!important; 
    }
    .px-4 {
        padding-right: 0rem!important;
        padding-left: 0rem!important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .navbar-nav .nav-link {
        color: #333333;
        font-family: 'inter' , sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.7em;
        padding: 6px 11px !important;
    }
    .slider-section {
        width: 100%;
        height: 640px;
    }
    .banner-text {
        width: 60%;
        height: auto;
        margin: auto;
        text-align: center;
        margin-top: 300px;
    }
    .col-img-bg {
        width: 40%;
        padding: 25px 16px;
        margin: auto;
    }
    .col-fe-bg {
        width: 44%;
        padding: 29px 15px;
    }
    .col-opt-bg {
        width: 80%;
        padding: 30px 20px;
        margin-right: 13px;
    }
    .colopt-text h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 39px;
    }
    .colopt-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 170.02%;
    }
    .colfe-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
    }
    .mgt-4 {
        margin-top: 0px;
    }
    .abt-text p {
        font-weight: 500;
        font-size: 14px;
        line-height: 198.52%;
    }
    .bannerslider-section {
        padding: 80px;
        margin-bottom: 8px;
    }
    .serhead-text h1 {
        font-weight: 700;
        font-size: 40px;
        line-height: 158.52%;
    }
    #sigimg {
        width: 110%;
        height: auto;
    }

}