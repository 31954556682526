body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.accordion-header button {
  width: 100% !important;
  text-align: left !important;
  padding: 10px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #E8EEF3 !important;
}

a {
  text-decoration: none !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.cursor-pointer {
  cursor: pointer !important;
}


@media (max-width: 991.98px) {
  .main-sidebar {
    transform: translate3d(-293px, 0, 0) !important;
  }

  .main-sidebar-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    backdrop-filter: blur(2px);
  }

  .main-sidebar.open {
    transform: translate3d(0, 0, 0) !important;
  }
}