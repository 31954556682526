#map{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

#instructions {
    position: absolute;
    margin: 20px;
    width: 25%;
    top: 0;
    bottom: 20%;
    padding: 20px;
    background-color: #fff;
    overflow-y: scroll;
    font-family: sans-serif;
}

.hide{
    display: none !important;
}

.show{
    display: block !important;
    /* display: block; */
}

.modal-y-axis{
    overflow-x: hidden;
    overflow-y: auto;
}

.bg-button{
    background: #fff;
}

.ul-css {
    list-style-type: none;
    padding: 0;
    border: 1px solid #ddd;
}
  
.ul-css .li-css {
    padding: 8px 16px;
    border-bottom: 1px solid #ddd;
}
  
.ul-css .li-css:last-child {
    border-bottom: none
}

.navlink-custom-border {
    border-right: transparent;
    border-left: transparent;
    border-top: transparent;
    border-bottom: transparent;
    font-size: 16px;
}

.navlink-custom-border-active {
    border-bottom-color: #f69b31 !important;
    border-bottom-width: 2.5px !important;
}

.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
    overflow:auto;
    max-height:300px;
}
ul.notification-list > li{
	margin-top: 0;
	background-color: #fff;
	border-bottom: 1px solid #f5f5f5;
    /* overflow:auto;
    max-height:300px; */
}
ul.notification-list > li:last-child {
	border-bottom: none;
}
ul.notification-list > li a {
	display: block;
	padding: 5px;
	border-radius: 2px;
}
ul.notification-list > li a:hover {
	background-color: #fafafa;
}
ul.notification-list > li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}

.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.noti-details {
    color: #282828;
    margin-bottom: 0;
}

.noti-time {
    font-size: 12px;
    color: #bdbdbd;
    margin: 0;
}


.avatar {
	background-color: #aaa;
	border-radius: 50%;
	color: #fff;
	display: inline-block;
	font-weight: 500;
	height: 41px;
	line-height: 38px;
	margin: 0 10px 0 0;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	vertical-align: middle;
	width: 41px;
	position: relative;
	white-space: nowrap;
}

.main-btn2 {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0px;
    padding: 0 42px;
    font-size: 18px;
    line-height: 60px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    z-index: 5;
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    background-color: #2f2fe9; 
}
/* .main-btn2:hover {
    border: 2px solid #2f2fe9;
    background-color: #fff;
} */
.main-btn2:hover {
    color: #fff !important;
}


.labels2 {
    color: white;
    background-color: black;
    font-family: "Lucida Grande", "Arial", sans-serif;
    font-size: 12px;
    text-align: center;
    width: auto;
    white-space: nowrap;
    margin-top: -40px;
    padding: 1px 5px 1px 5px;
    /* padding-right: 5px;
    padding-left: 5px; */
}

.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
}
.marker:hover {
    z-index: 1;
}
/* .features-section{
	position: relative;
	padding: 25%;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
	background-image: url(../images/Ridetifyloginbg.png);
    margin: 0;
} */
.features-section{
	padding: 20%;
    margin: 0;
	background-image: url(../images/Ridetifyloginbg.png);
    background-size: cover;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;

}


/* .features-section::before{ */
    /* background-color: #fff; */
    /* background-position: right; */
	/* background-image: url(../images/Ridetifyloginbg.png); */
    /* content: ""; */
    /* position: absolute; */
    /* left: 0; */
    /* bottom: 0; */
    /* background-repeat: no-repeat; */
    /* background-size: contain; */
    /* width: 100%; */
    /* top: 0; */
    /* z-index: -1; */
    /* background-size: 100% 100%; */    
/* } */

/* body{background:#ecf0f1;}
a{text-decoration:none;} */
.firstLine {
    font-size: 20px;
    font-weight: 300;
}.secondLine {
    font-size: 32px;
    line-height: 36px;
    font-weight: 600;
} .thirdLine {
    font-size: 22px;
    line-height: 32px;
    font-weight: 300;
}.fourthLine {
    font-size: 12px;
    font-weight: 300;
    position: relative;
    top: -10px;
}

.badges {
    position:relative;
    margin:140px auto;
    width:200px;
    height:200px;
    background:#2f2fe9;
    border-radius:100%;
    color: #FFF;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Open Sans",sans-serif;
    -webkit-animation: 3s ease-in-out 0s normal none infinite running swing;
    -moz-animation: 3s ease-in-out 0s normal none infinite running swing;
    -o-animation: 3s ease-in-out 0s normal none infinite running swing;
    animation: 3s ease-in-out 0s normal none infinite running swing;
    
    -webkit-transform-origin: 100px -71px;
    -moz-transform-origin: 100px -71px;
    -o-transform-origin: 100px -71px;
    transform-origin: 100px -71px;
}
.badges:before{
    content:'';
    position:absolute;
    top:90px;left:90px;
    width:20px;height:20px;
    border-radius:100%;
    -webkit-box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9,20px -98px #2f2fe9,39px -94px #2f2fe9,56px -85px #2f2fe9,71px -72px #2f2fe9,
                83px -57px #2f2fe9,93px -40px #2f2fe9,98px -20px #2f2fe9,100px 0px #2f2fe9,
                
                -20px -98px #2f2fe9,-39px -94px #2f2fe9,-71px -72px #2f2fe9,
                -56px -85px #2f2fe9,-83px -57px #2f2fe9,-93px -40px #2f2fe9,-98px -20px #2f2fe9,-100px 0px #2f2fe9,
                
                0px 100px #2f2fe9,-20px 98px #2f2fe9,-39px 94px #2f2fe9,-56px 85px #2f2fe9,
                -71px 72px #2f2fe9,-83px 57px #2f2fe9,-93px -40px #2f2fe9,-98px 20px #2f2fe9,-93px 40px #2f2fe9,
                
                20px 98px #2f2fe9,39px 94px #2f2fe9,56px 85px #2f2fe9,
                71px 72px #2f2fe9,83px 57px #2f2fe9,93px 40px #2f2fe9,98px 20px #2f2fe9;
                
    
    -moz-box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9,20px -98px #2f2fe9,39px -94px #2f2fe9,56px -85px #2f2fe9,71px -72px #2f2fe9,
                83px -57px #2f2fe9,93px -40px #2f2fe9,98px -20px #2f2fe9,100px 0px #2f2fe9,
                
                -20px -98px #2f2fe9,-39px -94px #2f2fe9,-71px -72px #2f2fe9,
                -56px -85px #2f2fe9,-83px -57px #2f2fe9,-93px -40px #2f2fe9,-98px -20px #2f2fe9,-100px 0px #2f2fe9,
                
                0px 100px #2f2fe9,-20px 98px #2f2fe9,-39px 94px #2f2fe9,-56px 85px #2f2fe9,
                -71px 72px #2f2fe9,-83px 57px #2f2fe9,-93px -40px #2f2fe9,-98px 20px #2f2fe9,-93px 40px #2f2fe9,
                
                20px 98px #2f2fe9,39px 94px #2f2fe9,56px 85px #2f2fe9,
                71px 72px #2f2fe9,83px 57px #2f2fe9,93px 40px #2f2fe9,98px 20px #2f2fe9;
                
    
    -o-box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9,20px -98px #2f2fe9,39px -94px #2f2fe9,56px -85px #2f2fe9,71px -72px #2f2fe9,
                83px -57px #2f2fe9,93px -40px #2f2fe9,98px -20px #2f2fe9,100px 0px #2f2fe9,
                
                -20px -98px #2f2fe9,-39px -94px #2f2fe9,-71px -72px #2f2fe9,
                -56px -85px #2f2fe9,-83px -57px #2f2fe9,-93px -40px #2f2fe9,-98px -20px #2f2fe9,-100px 0px #2f2fe9,
                
                0px 100px #2f2fe9,-20px 98px #2f2fe9,-39px 94px #2f2fe9,-56px 85px #2f2fe9,
                -71px 72px #2f2fe9,-83px 57px #2f2fe9,-93px -40px #2f2fe9,-98px 20px #2f2fe9,-93px 40px #2f2fe9,
                
                20px 98px #2f2fe9,39px 94px #2f2fe9,56px 85px #2f2fe9,
                71px 72px #2f2fe9,83px 57px #2f2fe9,93px 40px #2f2fe9,98px 20px #2f2fe9;
                
    
    box-shadow: 0px -82px 0px -2px #fff, 0px -100px #2f2fe9,20px -98px #2f2fe9,39px -94px #2f2fe9,56px -85px #2f2fe9,71px -72px #2f2fe9,
                83px -57px #2f2fe9,93px -40px #2f2fe9,98px -20px #2f2fe9,100px 0px #2f2fe9,
                
                -20px -98px #2f2fe9,-39px -94px #2f2fe9,-71px -72px #2f2fe9,
                -56px -85px #2f2fe9,-83px -57px #2f2fe9,-93px -40px #2f2fe9,-98px -20px #2f2fe9,-100px 0px #2f2fe9,
                
                0px 100px #2f2fe9,-20px 98px #2f2fe9,-39px 94px #2f2fe9,-56px 85px #2f2fe9,
                -71px 72px #2f2fe9,-83px 57px #2f2fe9,-93px -40px #2f2fe9,-98px 20px #2f2fe9,-93px 40px #2f2fe9,
                
                20px 98px #2f2fe9,39px 94px #2f2fe9,56px 85px #2f2fe9,
                71px 72px #2f2fe9,83px 57px #2f2fe9,93px 40px #2f2fe9,98px 20px #2f2fe9;
}
.badges:after{
    content:'';
    position:absolute;
    top:-70px;left:99px;
    width:2px;height:81px;
    border-radius:0%;
    background:#000;
}

@-webkit-keyframes swing{
    0%{-webkit-transform:rotate(5deg);}
    50%{-webkit-transform:rotate(-5deg);}
    100%{-webkit-transform:rotate(5deg);}
}
@-moz-keyframes swing{
    0%{-moz-transform:rotate(5deg);}
    50%{-moz-transform:rotate(-5deg);}
    100%{-moz-transform:rotate(5deg);}
}
@-o-keyframes swing{
    0%{-o-transform:rotate(5deg);}
    50%{-o-transform:rotate(-5deg);}
    100%{-o-transform:rotate(5deg);}
}
@keyframes swing{
    0%{transform:rotate(5deg);}
    50%{transform:rotate(-5deg);}
    100%{transform:rotate(5deg);}
}

.circle p{
    border: 4px double #f69b31;
    padding: 15px 0px;
    width: 110px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .payment-price {
    position: absolute;
    left: 65px;
    width: 131px;
    top: 20px;
}

.mile-text {
    border-left: 4px double #f69b31;
    margin-left: 55px;
    margin-top: -16px;
}
.mile-text p {
    writing-mode: vertical-lr;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
}

.mile-text2 {
    border: none;
    margin-left: 10px;
    /* margin-top: -146px; */
    margin-top: -100%;
}
.mile-text2 p {
    writing-mode: vertical-lr;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
}

.col-7.mitbsnav {
    /* margin-left: 180px; */
    margin-left: 20%;
    /* align-items: center ; */
    text-align: center ;
}

/* .col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
} */

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    border-radius: 2px;
    /* border-radius: 0 5px 5px 0; */
}

.mitbsnav ul li.active {
    background-color: #f69b31;
}

.mitbsnav ul li:first-child {
    border-radius: 2px;
    /* border-right: none; */
}

.nav-justified .nav-item, .nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.mitbsnav ul li {
    border: 1px solid #f69b31;
    padding: 5px 0;
    color: #f69b31;
    background-color: #fff;
    font-size: 14px;
}

.align-items-center {
    align-items: center !important;
}

.mitbsnav ul li a {
    color: #f69b31;
}

.mitbsnav ul li.active a {
    color: #fff;
}

.container-custom {
    position: fixed;
    /* right: 0; */
    left: 0 !important;
    top: 0px !important;
    width: 100% !important;
    /* z-index: 9999; */
    background: #F2F4F7;
    /* margin-left: 240px; */
    /* margin-top: 63px; */
    min-height: 100vh !important;
}


.border-bottom-black {
    border-bottom: 1px solid #808080;
}

.custom-badge {
    background-color: #fc6075;
    color: #fff;
    font-weight: 700;
    position: absolute;
    right: 10px;
    top: -8px;
}


.accordion-button::after {
    display: block;
    }


.topnav-dropdown-footer a {
    /* display: block; */
    /* text-align: center; */
    /* color: #333; */
    /* color: #252d37; */
    color: #1c4a85;
    font-size: 13px !important;
}

.active-3 {
    color: #F69B31 !important;
}
.home-col {
    color: #F69B31 !important;
}

.form-controlnew {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-controlnew:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.btn-secondarynew {
    background: #293E4A;
    /* background: #3894bb; */
    border-radius: 6px;
    font-family: 'Inter' , sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-transform: uppercase;
    color: #F69B31;
    /* color: #143443; */
}

.btn-secondarynew:hover{
    background: #293E4A;
    /* background: #3894bb; */
    border-radius: 6px;
    color: #F69B31;
    /* color: #143443 */
}

.profile-view .pro-edit {
    position: absolute;
    right: 0;
    top: 0;
}
.edit-icon {
    background-color: #eee;
    border: 1px solid #e3e3e3;
    border-radius: 24px;
    color: #606060;
    float: right;
    font-size: 12px;
    line-height: 24px;
    min-height: 26px;
    text-align: center;
    width: 26px;
}
.edit-icon:hover {
    /* background-color: #ff9b44; */
    /* border-color: #ff9b44; */
    color: #fff;
}

.fileupload.btn {
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(33, 33, 33, 0.5);
    border-radius: 0;
    padding: 3px 10px;
    border: none;
}
.fileupload input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: -3px;
    top: -3px;
    padding: 5px;
}

.profile-img-wrap.edit-img .fileupload.btn {
    left: 0;
}
.profile-img-wrap {
    height: 120px;
    position: absolute;
    width: 120px;
    background: #fff;
    overflow: hidden;
}

.profile-img-wrap img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
}
.profile-img-wrap.edit-img {
    border-radius: 50%;
    /* margin: 0 auto 30px; */
    position: relative;
}
.profile-img-wrap {
    position: relative;
    margin: 0 auto;
}
.profile-view .profile-img-wrap {
    height: 180px;
    width: 120px;
}
.btn-text {
    color: #fff;
}
.inline-block {
    border: solid 1px #B0B0B0
}

table.table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
}
table.table td h2.table-avatar.blue-link a {
    color: #007bff;
}

.avatarr {
    /* background-color: #aaa; */
    /* border-radius: 50%; */
    color: #fff;
    display: inline-block;
    font-weight: 500;
    height: 38px;
    line-height: 38px;
    margin: 0 10px 0 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 38px;
    position: relative;
    white-space: nowrap;
}

.mail-view-title {
	font-weight: 400;
	font-size: 32px;
	margin: 0;
}
.mail-view-action {
	float: right;
}
.mail-sent-time {
	float: right;
}
.sender-name {
    display: block;
    font-weight: 600;
}
.receiver-name {
    color: #777;
}
.mailview-header {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 15px;
}
.mailview-footer {
	border-top: 1px solid #ddd;
	margin-top: 20px;
	padding-top: 15px;
}
.mailview-footer .btn-white {
	min-width: 102px;
}

.btn-white {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
}

.left-action {
    text-align: center;
    margin-bottom: 15px;
}

.pricing-6-item {
    border: 2px solid #293E4A;
    border-radius: 10px;
    box-shadow: 0 1px 5px 0 #293E4A;
    padding: 30px 30px 42px;
}

.pricing-6-item p:after {
    background: transparent;
    border-bottom: 1px dashed #1f72b0;
    content: "";
    display: table;
    height: 1px;
    left: 0;
    margin-top: 20px;
    position: relative;
    width: 100%;
}
.mail-date {
    text-align: right;
}
.unread .name,
.unread .subject,
.unread .mail-date {
  /* color: #000; */
  /* font-weight: 600; */
  background-color: #ddd;
  color: #000;
  font-weight: 700;
  font-family: Arial;
}

.bg-msgs {
    background-color: lightgray !important;
}

.sender-img {
    float: left;
    margin-right: 10px;
    /* width: 40px; */
    border-radius: 50%;
    height: 40px;
    width: 40px;
}


/* CSS used here will be applied after bootstrap.css */

.dropdown3 {
    display:inline-block;
    margin-left:20px;
    padding:10px;
  }


.glyphicon-bell {
   
    font-size:1.5rem;
  }

.notifications3 {
   min-width:420px; 
  }
  
  .notifications3-wrapper {
     overflow:auto;
      max-height:250px;
    }
    
 .menu-title {
     color:#ff7788;
     font-size:1.5rem;
      display:inline-block;
      }
 
.glyphicon-circle-arrow-right {
      margin-left:10px;     
   }
  
   
 .notification-heading, .notification-footer3  {
 	padding:2px 10px;
       }
      
        
.dropdown3-menu.divider {
  margin:5px 0;          
  }

.item-title {
  
 font-size:1.3rem;
 color:#000;
    
}

.notifications3 a.content {
 text-decoration:none;
 background:#ccc;

 }
    
.notification-item3 {
 padding:10px;
 margin:5px;
 background:#ccc;
 border-radius:4px;
 }



